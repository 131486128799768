import React from 'react';
import BottomButton from './BottomButton';
import IcomoonIcon from '../../shared/IcomoonIcon';
import { color } from '../../../utils/style';

const Burger = (props) => (
  <BottomButton {...props}>
    <IcomoonIcon icon={props.open ? 'burger-opened' : 'burger-closed'} color={color.lightningYellow} className="" size={40} />
    <span>เมนู</span>
  </BottomButton>
);

export default Burger;
