import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import ProfileButton from './ProfileButton';
import IcomoonIcon from '../shared/IcomoonIcon';
import { flex, color } from '../../utils/style';
import { invokePopup } from '../shared/PopupWindow';
import { StoreContext } from '../../store';
import { setProfileMenu, setUser } from '../../store/actions';
import { freespinLogout } from '../../utils/api/freespin';
import dictionary from '../../utils/dictionary';

const Wrap = styled.ul`
  width: 100%;
  height: max-content;
  z-index: 1000;
  background-color: ${color.minsk};
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0 0 14px 14px;
  
  & li {
    margin: 0;    
    width: 100%;
    border-bottom: ${color.transparentWhite2} 1px solid;
    padding: 14px 18px;
    cursor: pointer;
    
    &.buttons{
      padding: 0px 15px;
            
      & svg{
        display: block!important;
        margin: auto;
      }
      & span {       
        display: block;
      }  
    }
    
    & a {
      width: 100%;
      height: 100%;
      
      ${flex};
      justify-content: flex-start;
      
      & span {
        margin-left: 20px;
        
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: 500;
        color: ${color.white};
        
        &.green {
          color: ${color.lightGreen};
          margin-left: 8px; 
        }
      }
    }
    
    &:last-child {
      border-bottom: none;
    }
    
    &:hover, &.active {
      background-color: rgba(255,255,255,0.1);
    }
  }
  
  & .buttons {
    border-bottom: none;
    padding-left: 0;
    margin: 20px 0;
    
    ${flex};
    justify-content: space-around;
    
    &:hover {
      background-color: transparent;
    }
  }
`;

const Menu = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { profile: { activeTab } } = state;

  const logout = (e) => {
    e.preventDefault();
    freespinLogout(() => { setUser(dispatch, ''); }, true);
  };
  const openWithdraw = (e) => invokePopup(e, 'withdraw-popup');
  const openDeposit = () => window.location = '/banking/';
  const menuChange = (item) => {
    if (activeTab === item) return false;
    setProfileMenu(dispatch, item);
  };

  return (
    <Wrap className="profile-menu">
      <li className="buttons">
        <ProfileButton onClick={openDeposit}>
          <IcomoonIcon icon="purse" size={18} />
          <span>ฝาก-ถอน</span>
        </ProfileButton>
        <ProfileButton onClick={openWithdraw}>
          <IcomoonIcon icon="money-hand" size={22} />
          <span>ข้อมูลการถอน</span>
        </ProfileButton>
      </li>
      <li
        className={activeTab === 'bets' ? 'active' : ''}
        onClick={() => menuChange('bets')}
      >
        <Link to="/profile/">
          <IcomoonIcon color={color.lightningYellow} icon="bets" size={24} />
          <span>{dictionary.bets}</span>
        </Link>
      </li>
      <li
        className={activeTab === 'winning_bonuses' ? 'active' : ''}
        onClick={() => menuChange('winning_bonuses')}
      >
        <Link to="/profile/">
          <IcomoonIcon color={color.lightningYellow} icon="winning-bonuses" size={24} />
          <span>{dictionary.winning_bonuses}</span>
        </Link>
      </li>
      <li
        className={activeTab === 'account_settings' ? 'active' : ''}
        onClick={() => menuChange('account_settings')}
      >
        <Link to="/profile/">
          <IcomoonIcon color={color.lightningYellow} icon="user-profile" size={24} />
          <span>{dictionary.accountSettings}</span>
        </Link>
      </li>
      <li onClick={logout}>
        <Link to="/">
          <IcomoonIcon color={color.lightningYellow} icon="log-out" size={24} />
          <span>ออกจากระบบ</span>
        </Link>
      </li>
    </Wrap>
  );
};

export default Menu;
