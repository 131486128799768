import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import { validateLink } from '../../utils/system';
import IcomoonIcon from './IcomoonIcon';

const SubMenu = styled('ul')`
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 999;
  text-align: left;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background-color: ${color.purple};
  text-transform: capitalize;
  font-weight: 300;
  font-size: 0;
  transition: all 0.2s ease-in-out;
  display: inherit;
  padding: 0 10px 0 10px;
  @media (max-width: ${breakpoints.md}) {
    display: none;
    background-color: ${color.lightPurple};
    position: relative;
    left: -12%;
  }
  li {
    &.submenu-item {
      padding-left: 0;
    }
    line-height: 2;
    a {
      &:active, &:hover {
        border-bottom: none;
      }
      .active
      {
        border-bottom: none;
      }
    }
  }  
  li:last-child:before {
      display: none;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    background-color: unset;
    //margin-left: 30px;
    padding-left: 0;
    
    & li {
      padding-left: 0;
      margin-top: 20px;
      height: auto;
      
      &.submenu-item {
        padding-left: 45px;
      }
    }
  }
`;

const SubMenuComp = ({ wordpress_children }) => (
  <SubMenu className="sub-menu">
    {wordpress_children?.map(({ wordpress_id, classes, attr, xfn, title, description, path }) => (
      <li key={wordpress_id} className={`submenu-item ${classes}`}>
        <Link activeClassName="active-sub" to={validateLink(path.replace('category/', ''))} replace>
          {attr && (
            <div className="subitem-arrow">
              {attr === 'bakkarat' && classes ? (
                <IcomoonIcon icon="play" color={color.white} size={16} alt="submenu" />
              ) : (
                <IcomoonIcon icon="arrow-right" color={color.white} size={16} alt="submenu" />
              )}
            </div>
          )}
          <span className="subitem-title">
            {title}
            {xfn && <span className="subitem-label">{xfn}</span>}
          </span>
          {description && <span className="subitem-description">{description}</span>}
        </Link>
      </li>
    ))}
  </SubMenu>
);

export default SubMenuComp;
