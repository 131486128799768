import React from 'react';
import styled from '@emotion/styled';
import { flex, color } from '../../../utils/style';

const StarFieldset = styled.div`
  width: 250px;
`;

const AllStarWrapper = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
`;

const StarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  pointer-events: none;
  
  ${flex};
  justify-content: space-between;
  
  & > .gatsby-image-wrapper {
    margin-right: 15px;
    
    &:first-child {
      margin-left: 2px;
    }
  }
  
  & > svg {
    margin-right: 8px;
    & > path {
      fill: ${color.lightningYellow};
    }
    
    &.active ~ svg {
      & > path {
        fill: none;
      }
    }
  }
`;

const StarInputs = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  
  ${flex};
  justify-content: space-between;

  & > input {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    cursor: pointer;
    
    &:nth-of-type(2) {
      left: 40px;
    }
    
    &:nth-of-type(3) {
      left: 80px;
    }
    
    &:nth-of-type(4) {
      left: 120px;
    }
    
    &:nth-of-type(5) {
      left: 160px;
    }
    
    &:hover {
      & + label {
        opacity: 0.2;
      }
    }
    
    &:checked {
      & + label {
        opacity: 0.3;
      }
    }
  }
  
  & > label {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${color.white};
    opacity: 0.08;
    pointer-events: none;
    
    & > span {
      visibility: hidden;
    }
  }
  
  &.background-none {
    & > label {
      background-color: transparent;
    }
  }
`;

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id, label, ...props
}) => (
  <>
    <input
      name={name}
      id={id}
      type="radio"
      value={id} // could be something else for output?
      checked={id === value}
      onChange={onChange}
      onBlur={onBlur}
      {...props}
    />
    <label htmlFor={id}><span>{label}</span>
    </label>
  </>
);

export const RadioButtonGroup = ({
  value, error, touched, id,
  label, children, stars, background,
}) => {
  const StarsComponent = stars;
  return (
    <StarFieldset>
      <legend>{label}</legend>
      <AllStarWrapper>
        <StarContainer>
          <StarsComponent />
        </StarContainer>
        <StarInputs className={!background ? 'background-none' : ''}>
          {children}
        </StarInputs>
      </AllStarWrapper>
    </StarFieldset>
  );
};
