import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { flex, color } from '../../utils/style';
import ProfileSvg from '../../icons/svg/ProfileSvg';
import { StoreContext } from '../../store';
import UserAvatar from '../../icons/avatar/UserAvatar';
import { setUserAvatar } from '../../store/actions';
import Loader from '../shared/Loader';

const Wrap = styled.div`
  width: max-content;
  height: max-content;
  position: relative;
  
  ${flex};
  
  & .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid ${color.haiti};
    
    position: absolute;
    top: 0;
    right: 0;
    
    &.online {
      background-color: ${color.lightGreen};
    }
    
    &.offline {
      background-color: ${color.razzmatazz};
    }    
  }
  & img{
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50rem;
  }
`;

const ProfileIcon = ({ online = false }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { userAvatar } = state.profile;

  // const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    if (!userAvatar) {
      // get user avatar - if it not exists
      setUserAvatar(dispatch);
    } else {
      // setIsLoaded(false);
    }
  }, [userAvatar]);

  return (
    <Wrap>
      {userAvatar ? <UserAvatar avatar={userAvatar} /> : <ProfileSvg />}
      <div className={`indicator ${online ? 'online' : 'offline'}`} />
    </Wrap>
  );
};
export default ProfileIcon;
