import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import StoreContext from './Context/StoreContext';
import { isMobile } from '../../utils/system';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PaymentSystems from '../Content/PaymentSystems';
import { getAuthUserName } from '../Auth/Utils';
import { breakpoints, color, reset, typography } from '../../utils/style';

// import Parallax from './Parallax';
import bg from '../../img/bg.jpg';
// import balls from '../../img/parallax/football.png';
// import BallsMobile from './BallsMobile';

const Content = styled('div')`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${color.stratos};
  background-image: url(${bg});
  background-size: 100% auto;
  background-repeat: repeat-y;
  &.inter-blocked,
  &.popup-blocked {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: -1;
    }
  }
  &.popup-blocked {
    .nav-wrap {
      position: static;
    }
    #app-header,
    .logo {
      z-index: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    &.open {
      position: fixed;
      z-index: 99;
    }
  }
`;

const ContentWrapper = styled('div')`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${breakpoints.tl}) {
    padding: 0 16px;
  }
`;

const Main = styled('main')`
  flex-grow: 1;
  margin-top: 30px;
  padding: 0 16px;
  @media (max-width: ${breakpoints.tl}) {
    margin-top: 10px;
  }
`;

// REFACTOR
// Parallax should be refactored like one of tasks.
export default ({ children, postCategory }) => {
  const [username, setUsername] = useState(getAuthUserName());
  const [isMob, setIsMob] = useState(true);

  useEffect(() => {
    const mob = isMobile('1024');
    if (window) setIsMob(mob);
  }, []);

  return [
    <Global
      key="reset"
      styles={css`
        ${reset} ${typography}
      `}
    />,
    <Helmet key="app-head">
      <html lang="th" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Helmet>,
    <StoreContext.Provider
      key="login-context-provider"
      value={{
        username,
        setUsernameCallback: setUsername,
        postCategory,
      }}
    >
      <Content key="app-content" id="all-content">
        <Header key="app-header" location="/" />
        <ContentWrapper>
          <Main key="app-content">{children}</Main>
          <PaymentSystems />
          <Footer key="app-footer" />
          {/* {!isMob && <Parallax />} */}
          {/* {isMob && <BallsMobile />} */}
        </ContentWrapper>
      </Content>
    </StoreContext.Provider>,
  ];
};
