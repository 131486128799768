import React from 'react';
const UpdateIcon = ({ width = '18', className = '', height = '18', viewBox='0 0 18 18' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" opacity=".7">
      <g>
        <g>
          <g fill="#FFF" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round">
            <path d="M2.838 3.975c.332.249.399.72.15 1.05-.598.798-.925 1.738-.925 2.725 0 2.345 1.77 4.277 4.046 4.533l-.014-.016c-.267-.266-.29-.683-.073-.976l.073-.084c.266-.267.683-.29.976-.073l.084.073 1.329 1.328c.266.267.29.683.073.977l-.073.084-1.329 1.329c-.293.292-.767.292-1.06 0-.267-.267-.29-.683-.073-.977l.073-.084.07-.069C3.03 13.56.563 10.943.563 7.75c0-1.32.436-2.573 1.225-3.625.249-.331.72-.398 1.05-.15zm4.317-3.4c.267.267.29.683.073.977l-.073.084-.069.07c3.133.235 5.601 2.851 5.601 6.044 0 1.32-.436 2.573-1.225 3.625-.249.331-.72.398-1.05.15-.332-.249-.399-.72-.15-1.05.598-.798.925-1.738.925-2.725 0-2.345-1.769-4.277-4.045-4.533l.013.016c.267.266.29.683.073.976l-.073.084c-.266.267-.683.29-.976.073l-.084-.073-1.329-1.328c-.266-.267-.29-.683-.073-.977l.073-.084L6.095.575c.293-.292.767-.292 1.06 0z" transform="translate(-1264.000000, -260.000000) translate(1264.000000, 260.000000) translate(2.750000, 1.250000)"/>
          </g>
          <path d="M0 0L18 0 18 18 0 18z" transform="translate(-1264.000000, -260.000000) translate(1264.000000, 260.000000)"/>
        </g>
      </g>
    </g>
  </svg>;

  export default UpdateIcon;
