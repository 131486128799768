import React from 'react';
import styled from '@emotion/styled';
import { useModalOpen } from '../shared/Modal';
import { color, gradient } from '../../utils/style';
import { mq, spreadProps } from '../../utils/mediaQuery';

const LoginBtn = styled.div`
  height: 44px;
  width: 115px;
  border: none;
  text-transform: capitalize;
  transition-duration: 0.4s;
  margin-right: 16px;
  padding: 10px 0;
  border-radius: 14px;
  user-select: none;
  cursor: pointer;
  position: relative;
  color: ${color.rose};
  background: ${color.rose};
  background: ${gradient.roseBittersweet};

  ${mq('md')} {
    width: 50%;
    margin-right: 0;
    background-color: transparent;
    border: 1px solid white;
  }

  &:before {
    content: '';
    display: flex;
    position: absolute;
    background-color: ${color.blueZodiac};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 1px;
    border-radius: 14px;
  }

  &:active,
  &:hover {
    background: ${color.rose};
    background: ${gradient.roseBittersweet};

    & > span {
      color: white;
    }
  }

  &:hover:before {
    background-color: transparent;
  }

  & > span {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${(props) => spreadProps(props.styleProps)}
`;

const LoginButton = ({ className }) => {
  const clickHandler = useModalOpen('login-popup-window');

  return (
    <LoginBtn onClick={clickHandler} className={className}>
      <span>เข้าสู่ระบบ</span>
    </LoginBtn>
  );
};

export default LoginButton;
