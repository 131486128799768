import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Star from './Star';

const StarIndex = ({ text1, text2, text3, text4, text5 }) => (
  <StaticQuery
    query={graphql`
      query {
        star1: file(relativePath: { eq: "withdraw/star-1.png" }) {
          childImageSharp {
            fixed(width: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        star2: file(relativePath: { eq: "withdraw/star-2.png" }) {
          childImageSharp {
            fixed(width: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        star3: file(relativePath: { eq: "withdraw/star-3.png" }) {
          childImageSharp {
            fixed(width: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        star4: file(relativePath: { eq: "withdraw/star-4.png" }) {
          childImageSharp {
            fixed(width: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        star5: file(relativePath: { eq: "withdraw/star-5.png" }) {
          childImageSharp {
            fixed(width: 27) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={({ star1, star2, star3, star4, star5 }) => {
      const starArrayImg = [
        { img: star1.childImageSharp.fixed, text: text1 },
        { img: star2.childImageSharp.fixed, text: text2 },
        { img: star3.childImageSharp.fixed, text: text3 },
        { img: star4.childImageSharp.fixed, text: text4 },
        { img: star5.childImageSharp.fixed, text: text5 },
      ];
      return starArrayImg?.map(({ img, text }) => <Star fixed={img || ''} text={text} key={text} />);
    }}
  />
);

export default StarIndex;
