import React from 'react';

export const isError = (errors, touched, key) => (errors[key]);

export const isErrorValidating = (errors, touched, key, validating) => errors[key] && touched[key] && validating;

export const FormError = ({ errors, touched, fieldKey, validating }) => (
  <>
    {
      (isError(errors, touched, fieldKey) && validating) && (
        <div className="error">
          <span>{errors[fieldKey]}</span>
        </div>
      )
    }
  </>
);
