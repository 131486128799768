import React, { useState, useMemo, useEffect, useContext } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { font, color, breakpoints, border, sizing, gradient } from '../../utils/style';
import ButtonComponent from '../shared/ButtonLink/index';
import SubMenu from '../shared/SubMenu';
import BtnChildMenu from './BtnChildMenu';
import Auth from '../Auth/Auth';
import { validateLink } from '../../utils/system';
import StoreContext from '../shared/Context/StoreContext';

const NavWrap = styled('div')`
  height: 60px;
  border-radius: ${border.borderRadius4};
  background-color: ${color.blueZodiac};
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;

  & * {
    user-select: none;
  }

  @media (max-width: ${breakpoints.md}) {
    height: 100vh;
    width: 100%;
    //left: -400px;
    top: 100%;
    position: absolute;
    z-index: 1;
    display: none;
    &.open {
      display: block;
      left: 0;

      > ul {
        display: block;
        overflow-y: scroll;
        @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
          padding-bottom: 60px;
        }
      }
    }

    background-color: ${color.darkPurple};
  }
`;

const Nav = styled('ul')`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${font.heading};
  font-weight: ${font.headingWeight};
  border-radius: ${border.borderRadius4};
  list-style: none;
  text-align: center;
  font-size: ${sizing.default};
  opacity: 1;
  text-transform: uppercase;
  padding: 0;

  li {
    position: relative;
    margin-top: 0;
    display: flex;
    vertical-align: bottom;
    white-space: nowrap;
    line-height: 3.9;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    //max-width: 10%;
    max-width: max-content;

    .btn-open svg {
      transform: rotate(90deg);

      &:hover {
        transform: rotate(90deg);
      }
    }

    &:hover {
      .sub-menu {
        display: flex;
        padding: 0 0 0 16px;
        width: 130%;
        top: 100%;
        font-size: inherit;
      }

      .btn-child-menu svg {
        transform: rotate(0deg);
      }
      
      > a {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }

    &.active {
      border-bottom: 3px solid red;
    }
  }

  a {
    height: 100%;
    display: flex;
    align-items: center;
    color: ${color.moonRaker};
    box-sizing: border-box;
    font-size: 14px;
    @media (max-width: ${breakpoints.md}) {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.63;
      letter-spacing: normal;
      color: ${color.moonRaker};
    }

    &:after {
      content: '';
      height: 3px;
      width: 0;
      display: block;
      background-color: ${color.pink};
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 50%;
      bottom: 0;
    }
  }

  a:active,
  .active {
    text-decoration: none;
    color: ${color.white};

    &:after {
      width: 100%;
      left: 0;
    }
  }

  .home {
    width: 200px;
    padding: 0 20px;
    margin: 0 20px;
    position: relative;

    a {
      display: block;
      text-align: center;
    }
  }

  @supports (-ms-ime-align: auto) {
    font-size: 1.125rem;
    .home::before,
    .home::after {
      border-radius: 0;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    height: 100%;

    position: fixed;
    left: 0;
    top: 74px;

    background-color: ${color.stratos};
    margin: 0;
    overflow-y: unset;
    flex-direction: column;
    display: none;
    z-index: 1;
    padding-top: 25px;

    li {
      width: 100%;
      height: auto;
      line-height: 3.2;
      text-align: left;
      margin: 10px 0 0 0;
      padding-left: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      & > a {
        height: max-content;
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.63;
        letter-spacing: normal;
        color: ${color.moonRaker};
        text-transform: uppercase;

        &:active,
        &:hover {
          color: ${color.moonRaker};
        }

        &::after {
          display: none;
        }
      }

      &.active {
        text-decoration: none;
        border-bottom: none;
        border-left: 3px solid ${color.pink};
        padding-left: 13px;

        &.li-open {
          border-left: 3px solid transparent;

          .active-sub {
            :before {
              background-color: #ff3952;
              content: '';
              height: 100%;
              left: 3px;
              position: absolute;
              width: 3px;
            }
          }
        }
      }

      & .btn-mobile {
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > div > svg {
          transform: rotate(0deg);
        }
      }

      & .btn-open {
        & > div > svg {
          transform: rotate(0);
        }
      }

      &.li-open {
        height: max-content;

        & > a {
          margin-top: -10px;
        }

        .btn-open {
          svg {
            transform: rotate(180deg);
          }
        }

        .sub-menu {
          display: flex;
          padding: 0;
          width: 130%;
          top: 100%;
          font-size: inherit;
        }
      }
    }

    &.open {
      display: block;
    }

    .home {
      display: block;
      position: absolute;
      right: 50%;
      width: 250px;
      padding: 0;
      margin-right: -125px;
      bottom: 100%;
      background: none;

      &::after,
      &::before {
        display: none;
      }

      div {
        background: none;
        padding: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    li {
      flex-direction: column;
      max-width: 100%;
      justify-content: start;

      &.li-open {
        & > a {
          margin-top: 0;
        }
      }

      & > a {
        line-height: 1;
        padding: 6px 0;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .home {
      margin-right: 0;
      right: 10%;
    }
  }

  @media (max-width: ${breakpoints.xxs}) {
    .home {
      right: 10px;
    }
  }
`;

const MainWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    display: block;
    margin-top: 50px;
    padding: 0 16px;

    & .login-button {
      border-radius: ${border.borderRadius14};
      border: none;
      color: ${color.pink};
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 49%;
      color: ${color.rose};
      background: ${color.rose};
      background: ${gradient.roseBittersweet};

      &:before {
        content: '';
        display: flex;
        position: absolute;
        background-color: ${color.blueZodiac};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        margin: 1px;
        border-radius: ${border.borderRadius14};
      }

      &:active,
      &:hover {
        background: ${color.rose};
        background: ${gradient.roseBittersweet};
      }

      &:hover:before {
        background-color: transparent;
      }
    }

    & .register-button {
      border-radius: ${border.borderRadius14};
      border: none;
      color: ${color.rose};
      background: ${color.rose};
      background: ${gradient.roseBittersweet};
      margin-left: 0;
      width: 49%;

      &:before {
        content: '';
        display: flex;
        position: absolute;
        background-color: ${color.blueZodiac};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        margin: 1px;
        border-radius: ${border.borderRadius14};
      }

      &:active,
      &:hover {
        background: ${color.rose};
        background: ${gradient.roseBittersweet};
      }

      &:hover:before {
        background-color: transparent;
      }
    }

    & .icons {
      display: none;
    }
  }
`;

const FooterLinksComponent = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  padding: 30px 0 0 33%;
`;

// REFACTOR.
// If it's gonna be enough time.

export default () => {
  const [open, setOpen] = useState('');
  const [isMobile, setMobile] = useState(false);
  const [location, setLocation] = useState({});
  const context = useContext(StoreContext);
  useEffect(() => {
    if (window) {
      const currPage = window.location.pathname;
      const el = currPage === '/promotion/' ? document.getElementById('//') : document.getElementById(currPage);

      if (el) {
        el.style[`${window.innerWidth && window.innerWidth < 980 ? 'borderLeft' : 'borderBottom'}`] = '3px solid red';
      }

      setMobile(window.outerWidth < 960);
      setLocation(window.location);
    }
  }, []);

  const MobMenu = useMemo(
    () => (
      <MainWrapper>
        <Auth
          styleProps={{
            display: 'flex',
            position: 'initial',
            padding: '0 0px',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
          }}
          balanceStyle={{
            width: '50% !important',
            maxWidth: '50%',
            padding: '0 15%',
            textAlign: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginRight: '0',
          }}
          userAccountStyle={{
            width: 'calc(50% - 16px) !important',
            padding: '10px 0',
            textAlign: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginLeft: '8px',
          }}
          LoginBtnProps={{
            width: '50% !important',
            marginRight: '0',
          }}
          RegisterBtnProps={{ width: 'calc(50% - 16px) !important' }}
        />
        <FooterLinksComponent className="icons">
          <ButtonComponent />
        </FooterLinksComponent>
      </MainWrapper>
    ),
    [isMobile],
  );
  const isActiveTab = (link) => {
    if (!context.postCategory) return location.pathname?.includes(validateLink(link));
    if (!context.postCategory.path) return false;

    return validateLink(link) === context.postCategory.path.replace('category/', '');
  };

  const choseSlug = (slug) => {
    const obj = {
      'category/articles': () => '/articles/',
      '%e0%b8%82%e0%b9%88%e0%b8%b2%e0%b8%a7%e0%b8%9f%e0%b8%b8%e0%b8%95%e0%b8%9a%e0%b8%ad%e0%b8%a5': () =>
        '/football-news/',
      rest: `/${slug}/`,
    };
    return obj[slug] ? obj[slug]() : obj.rest;
  };

  const isCurrentOpen = (link) => open === link;

  const chooseSubMenu = (link) => {
    setOpen(link === open ? '' : link);
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
            nodes {
              id
              name
              slug
              items {
                title
                classes
                attr
                object_slug
                path
                target
                url
                wordpress_children {
                  attr
                  classes
                  description
                  path
                  title
                  xfn
                  object_slug
                  wordpress_id
                  target
                  url
                }
              }
            }
          }
        }
      `}
      render={({ allWordpressWpApiMenusMenusItems: { nodes } }) => (
        <NavWrap id="nav">
          <Nav>
            {nodes[0].items?.map(({ object_slug, type_label, wordpress_children, path, title }) => {
              const rightUrl = (path.indexOf('/') === path.lastIndexOf('/') ? '/' : '') + path.replace(/category/i, '');
              return (
                <li
                  className={
                    ` ${object_slug === '!home' ? 'home' : ''}` +
                    ` ${isActiveTab(rightUrl) ? 'active' : ''}` +
                    ` ${isCurrentOpen(rightUrl) ? 'li-open' : ''}`
                  }
                  id={choseSlug(object_slug)}
                  key={type_label + object_slug}
                  onMouseEnter={() => chooseSubMenu(rightUrl)}
                  onMouseLeave={() => chooseSubMenu(rightUrl)}
                >
                  <Link to={validateLink(rightUrl)}>{title}</Link>
                  {wordpress_children && (
                    <div className={isCurrentOpen(rightUrl) ? 'btn-open' : 'btn-mobile'}>
                      <BtnChildMenu />
                      {isCurrentOpen(rightUrl) && <SubMenu wordpress_children={wordpress_children} />}
                    </div>
                  )}
                </li>
              );
            })}
            {MobMenu}
          </Nav>
        </NavWrap>
      )}
    />
  );
};
