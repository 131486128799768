/* eslint-disable max-len */
import React from 'react';

const LogoIcon = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width="155" height="46" viewBox="0 0 780.99 231.23">
      <g id="Layer_1-2">
        <polygon className="cls-1" fill="#ed2255"
                 points="109.31 86.06 132.5 99.2 132.5 22.42 109.96 0 56.78 0 33.82 22.93 33.82 30.94 57.07 30.86 63.87 23.96 102.81 23.96 109.51 30.66 109.31 86.06"/>
        <polygon className="cls-1" fill="#ed2255"
                 points="33.82 80.93 33.82 87.35 109.57 130.38 109.57 174.07 108.18 175.63 108.18 205.1 109.57 205.1 132.5 182.14 132.5 117.5 68.14 80.79 33.82 80.93"/>
        <polygon className="cls-1" fill="#ed2255"
                 points="59.56 176.6 56.78 174.07 56.78 117.44 33.82 104.74 33.82 182.56 56.78 205.87 59.56 205.88 59.56 176.6"/>
        <polygon className="cls-1" fill="#ed2255"
                 points="19.58 66.09 72.15 66.09 72.15 68.36 94.56 81.31 94.56 66.09 94.5 44.45 11.85 44.45 0 56.3 0 80.25 19.07 99.2 19.58 66.09"/>
        <polygon className="cls-1" fill="#ed2255"
                 points="147.37 44.45 147.37 66.09 147.77 66.09 147.77 99.2 170.19 76.76 170.19 54.15 160.4 44.45 147.37 44.45"/>
        <polygon className="cls-1" fill="#ed2255"
                 points="73.18 126.31 95.08 138.96 95.08 220.12 84.13 231.23 73.18 220.32 73.18 126.31"/>
        <path className="cls-2" fill="#ffffff"
              d="M239.02,42.52c21.85,0,33.08,13.07,33.08,35.93v4.49h-21.23v-5.92c0-10.21-4.08-14.09-11.23-14.09s-11.23,3.88-11.23,14.09c0,29.4,43.9,34.91,43.9,75.75,0,22.87-11.43,35.93-33.48,35.93s-33.48-13.07-33.48-35.93v-8.78h21.23v10.21c0,10.21,4.49,13.88,11.64,13.88s11.64-3.68,11.64-13.88c0-29.4-43.9-34.91-43.9-75.75,0-22.87,11.23-35.93,33.07-35.93Z"/>
        <path className="cls-2" fill="#ffffff"
              d="M352.33,77.03v5.1c0,14.7-4.49,24.09-14.5,28.79,12.05,4.7,16.74,15.52,16.74,30.63v11.64c0,22.05-11.64,33.89-34.1,33.89h-35.32V44.16h33.89c23.28,0,33.28,10.82,33.28,32.87ZM307.62,64.57v37.77h8.78c8.37,0,13.47-3.68,13.47-15.11v-7.96c0-10.21-3.47-14.7-11.43-14.7h-10.82ZM307.62,122.76v43.9h12.86c7.55,0,11.64-3.47,11.64-14.09v-12.45c0-13.27-4.29-17.36-14.5-17.36h-10Z"/>
        <path className="cls-2" fill="#ffffff"
              d="M366.22,78.46c0-22.87,12.05-35.93,34.1-35.93s34.1,13.07,34.1,35.93v74.32c0,22.87-12.05,35.93-34.1,35.93s-34.1-13.07-34.1-35.93v-74.32ZM388.68,154.21c0,10.21,4.49,14.09,11.64,14.09s11.64-3.88,11.64-14.09v-77.18c0-10.21-4.49-14.09-11.64-14.09s-11.64,3.88-11.64,14.09v77.18Z"/>
        <path className="cls-2" fill="#fff"
              d="M516.08,77.03v5.1c0,14.7-4.49,24.09-14.5,28.79,12.05,4.7,16.74,15.52,16.74,30.63v11.64c0,22.05-11.64,33.89-34.1,33.89h-35.32V44.16h33.89c23.28,0,33.28,10.82,33.28,32.87ZM471.37,64.57v37.77h8.78c8.37,0,13.47-3.68,13.47-15.11v-7.96c0-10.21-3.47-14.7-11.43-14.7h-10.82ZM471.37,122.76v43.9h12.86c7.56,0,11.64-3.47,11.64-14.09v-12.45c0-13.27-4.29-17.36-14.5-17.36h-10Z"/>
        <path className="cls-2" fill="#ffffff"
              d="M554.26,104.39h30.83v20.42h-30.83v41.86h38.79v20.42h-61.25V44.16h61.25v20.42h-38.79v39.81Z"/>
        <path className="cls-2" fill="#ffffff" d="M600.4,44.16h69.42v20.42h-23.48v122.5h-22.46v-122.5h-23.48v-20.42Z"/>
        <path className="cls-1" fill="#ed2255" d="M684.56,44.16h38.62v11.36h-13.06v68.14h-12.49V55.51h-13.06v-11.36Z"/>
        <path className="cls-1" fill="#ed2255"
              d="M755.09,100.6l8.52-56.45h17.38v79.5h-11.81v-57.01l-8.63,57.01h-11.81l-9.31-56.22v56.22h-10.9V44.16h17.38l9.2,56.45Z"/>
      </g>
    </svg>
  </>
);

export default LogoIcon;
