import React from 'react';
import styled from '@emotion/styled';
import { border, color, font, resetButton, sizing, breakpoints } from '../../utils/style';

const Wrap = styled.button`
  ${resetButton};
  max-width: 85px;
  width: 100%;
  padding: 5px 0px;
  border-radius: ${border.borderRadius14};
  box-shadow: -20px 20px 14px 0 rgba(189, 66, 250, 0);
  background-color: ${color.transparentWhite2};

  color: ${color.white};
  font-family: ${font.default};
  font-size: ${sizing.small};
  font-weight: ${font.medium};

  & > svg {
    height: 22px;
  }

  &:hover {
    background-color: ${color.activeBlue};
  }

  &:active {
    background-color: ${color.activeBlue};
  }
`;

const ProfileButton = ({ children, className = '', onClick = null }) => (
  <Wrap className={className} onClick={onClick}>
    {children}
  </Wrap>
);

export default ProfileButton;
