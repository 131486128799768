import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import IcomoonIcon from '../shared/IcomoonIcon';
import { breakpoints, color } from '../../utils/style';
import { isMobile } from '../../utils/system';

const El = styled('div')`
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 20px;
  margin: 0 0 0 4px;

  svg {
    transition: transform 0.2s ease-out;
    transform: rotate(0deg);

    &:hover {
      transform: rotate(90deg);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    position: absolute;
    height: 100%;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      position: absolute;
      top: 6px;
    }
  }
`;

const BtnChildMenu = () => {
  const [mobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobile(960));
  });

  return (
    <El aria-label="ChildMenu" id="icon-triangle">
      {mobile ? (
        <IcomoonIcon className="icon" icon="arrow-down-simple" color={color.moonRaker} size={16} alt="menu" />
      ) : (
        <IcomoonIcon className="icon" icon="play3" color={color.moonRaker} size={10} alt="menu" />
      )}
    </El>
  );
};

export default BtnChildMenu;
