import React, { useMemo } from 'react';
import IcomoonIcon from '../IcomoonIcon';
import { color, SocialIconWrapper } from '../../../utils/style';

const ButtonComponent = ({
  ButtonArr = [
    {
      to: process.env.FACEBOOK_LINK,
      iconName: 'facebook',
    },
    {
      to: process.env.TWITTER_LINK,
      iconName: 'twitter',
    },
    {
      to: process.env.YOUTUBE_LINK,
      iconName: 'youtube',
    },
  ],
}) => {
  const Btns = useMemo(
    () =>
      ButtonArr?.map(({ to, iconName }) => (
        <SocialIconWrapper href={to} key={to}>
          <IcomoonIcon color={color.lightningYellow} icon={iconName} size={40} />
        </SocialIconWrapper>
      )),
    [ButtonArr],
  );

  return Btns;
};

export default ButtonComponent;
