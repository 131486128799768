import React from 'react';
import styled from '@emotion/styled';

const WrapAvatar = styled.div`
  .profile-avatar {
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    min-width: 28px;
    min-height: 28px;
  }
`;

const UserAvatar = ({ avatar }) => (
  <WrapAvatar>
    <div style={{ backgroundImage: `url(${avatar})` }} className='profile-avatar' />
  </WrapAvatar>
);

export default UserAvatar;
