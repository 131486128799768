import React, {useEffect, useContext, useState} from 'react';
import styled from '@emotion/styled';
import { setBonuses } from '../../store/actions';
import { StoreContext } from '../../store';
import { predictionGetUserInfo } from '../../utils/api/prediction';
import dictionary from '../../utils/dictionary';
import { color } from '../../utils/style';
import UpdateIcon from "../../icons/auth/UpdateIcon";

const Wrap = styled.div`
  width: max-content;
  height: 100%;
  margin-left: 16px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  .update {
    position: absolute;
    top: 17px;
    right: 35px;
    background: none;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    &:hover, &:active {
      opacity: 0.6;
    }
    &.loading {
      cursor: default;
      opacity: 0.5;
      svg {
        animation:spin 4s linear infinite;
      }
    }
  }

  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  
  & .title {
    display: flex;

    & > span {
      opacity: 0.5;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: ${color.moonRaker};
      text-transform: uppercase;
    }
  }
  
  & .value {
    display: flex;
    
    & > span {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      color: ${color.supernova};
    }
  }
`;

const Bonuses = ({ className = '' }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { user: { bonuses } } = state;
  const [balanceLoading, setBalanceLoading] = useState(false);

  // useEffect(() => {
  //   if (bonuses) return () => {};
  //
  //   predictionGetUserInfo()
  //     .then((json) => {
  //       if (!json) setBonuses(dispatch, "N/A");
  //
  //       const { v_bonuses } = json;
  //       setBonuses(dispatch, v_bonuses || "N/A");
  //     })
  //     .catch((err) => {
  //       // console.log('Bonuses request: ', err);
  //     });
  // }, []);
  //
  const updateContextBalance = () => {
    if (bonuses) return () => {};

    setBalanceLoading(true);
    predictionGetUserInfo()
      .then((json) => {
        if (!json) setBonuses(dispatch, "N/A");

        const { v_bonuses } = json;
        setBonuses(dispatch, v_bonuses || "N/A");
        setBalanceLoading(false);
      })
      .catch((err) => {
        // console.log('Bonuses request: ', err);
        setBalanceLoading(false);
      });
  };

  return (
    <Wrap className={className}>
      <button className={balanceLoading ? "update loading" : "update"} aria-label={"Update"}
              onClick={() => updateContextBalance()}>
        <UpdateIcon/>
      </button>
      <div className="title">
        <span>{dictionary.bonuses}</span>
      </div>
      <div className="value">
        <span>{bonuses ?? "N/A"}</span>
      </div>
    </Wrap>
  );
};

export default Bonuses;
