import Cookies from 'universal-cookie';
import { activationUrl } from './Config';
import { clearToken, clearUserName } from '../../utils/api';

const cookies = new Cookies();

const setAuthToken = (token) => cookies.set('Auth-Token', token, { path: '/' });

const getAuthToken = () => cookies.get('Auth-Token', { path: '/' });

const getAuthHeader = () => `Bearer ${getAuthToken()}`;

const resetAuthToken = () => {
  cookies.remove('Auth-Token', { path: '/' });
};

const setAuthUserName = (username) => cookies.set('Auth-Token-User-Name', username, { path: '/' });

const getAuthUserName = () => cookies.get('Auth-Token-User-Name', { path: '/' });

const resetAuthUserName = () => {
  cookies.remove('Auth-Token-User-Name', { path: '/' });
};

const hasAuthToken = () => {
  const token = getAuthToken();
  return token && token.length > 0;
};

// Refactor this. Stop changing styles by id.
const Logout = (event, clearUser = false) => {
  clearToken();
  clearUserName();
  if (clearUser) clearUser();

  const layout = document.getElementById('all-content');
  const nav = document.getElementById('nav');
  const header = document.getElementById('header');
  if (layout) layout.classList.remove('open');
  if (nav) nav.classList.remove('open');
  if (header) header.style = { ...header.style, backgroundColor: '#00011c', top: '-1px' };
};

const activationUser = async (path, query, activationCallback, errorCallback) => {
  const response = await fetch(activationUrl, {
    method: 'POST',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const json = await response.json();
    if (json.error && json.message) {
      errorCallback(json.error);
    } else {
      setAuthToken(json.token);
      setAuthUserName(json.username);
      activationCallback(true);
    }
  } else {
    // console.log('Activation Error HTTP: ' + response.status);
    // need to implement error log without console
    // IMPLEMENT ERROR UTIL
  }
};

export {
  setAuthToken,
  getAuthToken,
  resetAuthToken,
  resetAuthUserName,
  hasAuthToken,
  Logout,
  activationUser,
  setAuthUserName,
  getAuthUserName,
  getAuthHeader,
};
