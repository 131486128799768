import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Menu from './index';
import liveCasino from '../../img/live-casino-m.svg';

// Small refactor
// Create one time useStaticQuery for every same component instead of writing all time 30 lines for the same thing.
export default () => (
  <StaticQuery
    query={graphql`
        {
          allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "live-casino" } }) {
            nodes {
              id
              name
              slug
              items {
                type_label
                title
                object_slug
                url
                path
              }
            }
          }
        }
      `}
    render={(data) => <Menu data={data} img={liveCasino} />}
  />
);
