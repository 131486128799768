import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import { appIsLoaded, currEnvIsDev, setIsMobile, setUser } from '../../store/actions';
import { breakpoints, color, sizing } from '../../utils/style';
import { isDevDomain, isMobile } from '../../utils/system';
import MainMenu from '../Menus/MainMenu';
import Contacts from './Contacts';
import BottomPanel from './mobile/BottomPanel';
import { getUserName, isToken, isUserName } from '../../utils/api';
import balls from '../../img/parallax/football.png';

const Header = styled.header`
  height: 100%;
  position: relative;
  background-color: ${color.blueZodiac};

  & > .full-size-bg {
    display: none;
  }

  @media (max-width: ${breakpoints.md}) {
    background-color: ${color.transparent};
    position: sticky;
    top: -1px;
    z-index: 999;
    width: 100%;
    height: 70px;
    transition: background-color ease 500ms;

    &.scrolled {
      background-color: ${color.stratos};
    }

    div:first-of-type {
      z-index: 1000;
    }

    & > .full-size-bg {
      display: block;

      position: absolute;
      top: 0;
      left: -16px;
      width: 100vw;
      height: 100%;

      background-color: ${color.stratos};
    }
  }
`;

export default ({ location }) => {
  const [isScroll, setScroll] = useState(false);
  const [state, dispatch] = useContext(StoreContext);
  const {
    application: { isLoaded },
  } = state;

  const detectScroll = () => {
    setScroll(window.scrollY > 0);
  };

  useEffect(() => {
    if (window) {
      document.addEventListener('scroll', detectScroll);
    }
  }, [isScroll]);

  useEffect(() => {
    if (window && !state.application.isLoaded) {
      const domain = isDevDomain();
      // First render indicator. After it's fired - code is firing on client side.
      appIsLoaded(dispatch);

      // Setup current environment is production(?) (boolean) into storage.
      currEnvIsDev(dispatch, domain);

      // Clear console in prod.
      if (!domain) console.clear(); // eslint-disable-line no-console
    }
  }, []);

  const resize = () => setIsMobile(dispatch, isMobile());

  // For already loaded application
  useEffect(() => {
    if (!state.application.isLoaded) return () => {};

    // Set authenticated user
    if (isUserName() && isToken() && !state.user.userName) setUser(dispatch, getUserName());
    // Set isMobile flag + start event for resize tracking
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [isLoaded]);

  const ContentWrapper = styled('div')`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    @media (max-width: ${breakpoints.tl}) {
      padding: 0 16px;
    }
  `;

  const Result = useMemo(
    () => (
      <Header className={isScroll ? 'header scrolled' : 'header'} id="header">
        <ContentWrapper>
          <Contacts />
          <MainMenu isHome={location.pathname === '/'} />
          <BottomPanel />
          <div className="full-size-bg" />
        </ContentWrapper>
      </Header>
    ),
    [location, isScroll],
  );

  return Result;
};
