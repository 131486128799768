import React from 'react';

const FreshChatBtnIcon = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M870.4 0c81.562 0 148.634 64.154 153.344 144.589l0.256 9.011v512c0 81.562-64.154 148.634-144.589 153.344l-9.011 0.256h-545.331l-241.357 193.178c-1.792 1.434-3.635 2.765-5.632 3.994l5.12-3.584c-2.048 1.638-4.147 3.072-6.349 4.352-1.28 0.717-2.56 1.434-3.942 2.048l-1.587 0.717c-1.178 0.512-2.355 0.973-3.584 1.382l-3.379 1.024-2.253 0.512-3.226 0.614-3.379 0.41-1.843 0.102-2.458 0.051h-1.843l-1.946-0.154 3.789 0.154c-2.611 0-5.222-0.205-7.731-0.563l-2.048-0.358c-1.28-0.256-2.56-0.512-3.789-0.87l-2.048-0.614-3.43-1.28c-0.717-0.256-1.382-0.512-2.099-0.87-1.229-0.512-2.406-1.126-3.584-1.792l-1.536-0.87c-1.229-0.768-2.406-1.536-3.584-2.406l-1.485-1.024c-4.864-3.789-9.011-8.397-12.237-13.619l3.584 5.069c-1.638-2.048-3.072-4.198-4.352-6.4-0.717-1.28-1.434-2.56-2.048-3.891l-0.973-2.15-1.126-3.072-1.024-3.328-0.512-2.253-0.614-3.226-0.205-1.69-0.154-1.536c-0.102-0.87-0.205-1.741-0.205-2.662v-820.992c0-81.562 64.154-148.634 144.589-153.344l9.011-0.256h716.8zM870.4 102.4h-716.8c-26.061 0-47.872 19.968-50.842 45.261l-0.358 5.939v712.653l172.288-137.83c1.792-1.434 3.635-2.765 5.632-3.994l-5.12 3.584c2.048-1.638 4.198-3.072 6.4-4.352 1.28-0.717 2.56-1.434 3.891-2.048l2.15-0.973 3.072-1.126 3.328-1.024 2.253-0.512 3.226-0.614 1.69-0.205 1.536-0.154 2.765-0.205h564.89c26.061 0 47.872-19.968 50.842-45.261l0.358-5.939v-512c0-26.061-19.968-47.872-45.261-50.842l-5.939-0.358zM716.8 460.8c28.262 0 51.2 22.938 51.2 51.2 0 26.266-19.763 47.923-45.21 50.842l-5.99 0.358h-409.6c-28.262 0-51.2-22.938-51.2-51.2 0-26.266 19.763-47.923 45.21-50.842l5.99-0.358h409.6zM716.8 256c28.262 0 51.2 22.938 51.2 51.2 0 26.266-19.763 47.923-45.21 50.842l-5.99 0.358h-409.6c-28.262 0-51.2-22.938-51.2-51.2 0-26.266 19.763-47.923 45.21-50.842l5.99-0.358h409.6z"
    />
  </svg>
);

export default FreshChatBtnIcon;
