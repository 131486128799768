import React from 'react';
import styled from '@emotion/styled';
import { flex } from '../../utils/style';
import loaderImage from '../../img/withdraw/loader.gif';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  
  ${flex};
  
  & img {
    width: 70px;
  }
`;

const Loader = () => (
  <Wrap>
    <img src={loaderImage} alt="loader.gif" />
  </Wrap>
);

export default Loader;
