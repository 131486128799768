import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../icons/selection.json';

const IcomoonIcon = ({ className, color, icon, size, alt }) => (
  <IcomoonReact iconSet={iconSet} className={className} color={color} size={size} icon={icon} alt={alt || 'alt'} />
);

IcomoonIcon.propTypes = {
  className: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default IcomoonIcon;
