import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints, area } from '../../utils/style';

const WrapperSection = styled('div')`
  width: 100%;
  height: max-content;
  margin-top: 60px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2); 

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
  padding: ${area.blockPadding};

  div {
    max-height: 72px;
    width: calc(100% / 6);
    :nth-of-type(5) {
      max-height: 55px;
    }
  }
  
  .payment-icon {
    height: 32px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    div {
      margin: 10px 0;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    border-bottom-width: 0;
    margin-top: 0px;
    
    .payment-icon {
      height: 22px;
    }
    
    div {
      width: 144px!important;
      height: 55px!important;
    }
  }
`;

// REFACTOR
// Need to try use object 'arguments' instead of creating new one. If it possible - refactor.
const PaymentSystems = () => (
  <StaticQuery
    query={graphql`
      query {
        royalOnline: file(relativePath: { eq: "RoyalOnline.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        joker: file(relativePath: { eq: "joker.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        mega888: file(relativePath: { eq: "mega888.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        maxbet: file(relativePath: { eq: "maxbet.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        royalHill: file(relativePath: { eq: "royalHill.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        redDragon: file(relativePath: { eq: "redDragon.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        live22: file(relativePath: { eq: "live22.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        pussy888: file(relativePath: { eq: "pussy888.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        royalRuby: file(relativePath: { eq: "royalRuby.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        kiss: file(relativePath: { eq: "kiss.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        goldenSlot: file(relativePath: { eq: "goldenSlot.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        holidayPalace: file(relativePath: { eq: "holidayPalace.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={({
      royalOnline,
      joker,
      mega888,
      maxbet,
      royalHill,
      redDragon,
      live22,
      pussy888,
      royalRuby,
      kiss,
      goldenSlot,
      holidayPalace,
    }) => {
      const args = [
        royalOnline,
        joker,
        mega888,
        maxbet,
        royalHill,
        redDragon,
        live22,
        pussy888,
        royalRuby,
        kiss,
        goldenSlot,
        holidayPalace,
      ];

      const mystyle = {
        opacity: '0.7',
      };
      return (
        <WrapperSection id="payment-systems">
          {args?.map((img) =>
            img && <Img style={mystyle} key={JSON.stringify(img)} alt="Our Sites" fixed={img.childImageSharp.fixed} />)}
        </WrapperSection>
      );
    }}
  />
);

export default PaymentSystems;
