import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { getLocalFile } from '../../utils/system';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  
  position: absolute;
  top: 0;
  left: 0;
  
  z-index: -1;
  
  & .ball-footer {
    width: 400px;
    height: max-content;
    position: absolute;
    bottom: -15px;
    left: -112px;
  }
  
  @media (min-width: 1024px) {
    display: none;
  }
`;

const FooterBall = () => {
  const balls = useStaticQuery(graphql`
      query {
          footer: file(relativePath: { eq: "footerBall.png" }) {
              childImageSharp {
                  fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <Wrap>
      <div className="ball-footer">
        {
          getLocalFile(balls.footer) &&
          <Img alt="ball-footer" fluid={getLocalFile(balls.footer)} />
        }
      </div>
    </Wrap>
  );
};

export default FooterBall;
