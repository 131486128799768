import React, { useCallback } from 'react';
import { Link } from 'gatsby';
import { convertMenuNames } from '../../utils/system';

export default ({
  data: {
    allWordpressWpApiMenusMenusItems: { nodes },
  },
  img,
}) => {
  const [node] = nodes;
  const { items: nodeItems, slug } = node;
  const [nodeItem] = nodeItems;
  const { path } = nodeItem;
  const Result = useCallback(
    () => (
      <>
        <div className="h4">
          <Link to={`/${path.replace(/category/i, '')}`}>
            {img && <img className="iconImage" src={img} alt="menuImg" />}
            {convertMenuNames(slug)}
          </Link>
        </div>
        <div className="desktop">
          <ul>
            {nodeItems?.map(({ type_label, object_slug, path, title }) => (
              <li key={type_label + object_slug}>
                <Link activeClassName="active" to={`/${path.replace(/category/i, '')}`}>
                  {title}
                </Link>
                <span className="arrow" />
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    [node, img],
  );
  return <Result />;
};
