import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import ProfileIcon from './ProfileIcon';
import { flex, breakpoints, color, border, gradient } from '../../utils/style';
import Bonuses from './Bonuses';
import IcomoonIcon from '../shared/IcomoonIcon';
import Menu from '../Profile/Menu';

const Wrap = styled.div`
  width: 210px;
  min-height: 50px;
  background-color: ${color.minsk};
  border-radius: 4px;
  padding: 0 15px;
  position: relative;

  .profile-menu {
    .buttons {
      button {
        span {
          margin-left: 0;
        }

        &:hover {
          background: ${color.rose};
          background: ${gradient.roseBittersweet};
        }
      }
    }
  }

  ${flex};
  justify-content: flex-start;

  & * {
    user-select: none;
  }

  & .section {
    width: 100%;
    height: 100%;
    cursor: pointer;

    ${flex};
    justify-content: left;
  }

  & div {
    height: 100%;

    ${flex};

    & .arrow {
      cursor: pointer;
    }
  }

  &.open {
    padding: 8px 15px;
    border-radius: ${border.borderRadius14} ${border.borderRadius14} 0 0;
    & .arrow {
      transform: rotate(0deg);
    }

    & ul {
      display: block;
    }
  }

  &.close {
    padding: 8px 15px;
    border-radius: ${border.borderRadius14};
    & .arrow {
      transform: rotate(180deg);
    }

    & ul {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const HeaderProfilePanel = () => {
  const [open, setOpen] = useState(false);

  const menuToggle = () => setOpen((open) => !open);

  return (
    <Wrap id="header-profile-panel" className={open ? 'open' : 'close'}>
      <div onClick={menuToggle} className="section">
        <ProfileIcon online />
        <Bonuses />
      </div>
      <div onClick={menuToggle}>
        <IcomoonIcon className="arrow" icon="triangle" size={8} />
      </div>
      <Menu />
    </Wrap>
  );
};

export default HeaderProfilePanel;
