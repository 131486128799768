import React, { useContext, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Link } from 'gatsby';
import { breakpoints, flex, formsAutoFillingStyles, resetButton, color } from '../../../utils/style';
import { isError, FormError } from '../../shared/Form/FormError';
import { RadioButton, RadioButtonGroup } from '../../shared/Form/Radio';
import EmojiIcons from '../../Content/Withdraw/EmojiIcons';
import StarsIcons from '../../Content/Withdraw/StarsIcons';
import { StoreContext } from '../../../store';
import { freespinWithdrawComment } from '../../../utils/api/freespin';
import dictionary from '../../../utils/dictionary';
import { isValidField } from '../../../utils/system';

const Wrap = styled.div`
  ${formsAutoFillingStyles};
  position: relative;
  
  & > form {
    width: 100%;
    height: 100%;
    
    & .field-wrap:not(:first-child) {
      margin-top: 24px;
    }
    
    & .field-wrap {
      width: max-content;
      
      ${flex};
      flex-direction: column;
      align-items: flex-start;
      
      & > label {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.5);
        
        margin-bottom: 14px;
        user-select: none;
      }
      
      & > div > legend {
        color: ${color.white};
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        margin-bottom: 17px;
        user-select: none;
      }
      
      & > input, & > textarea {
        border-radius: 4px;
        border: solid 1px rgba(255, 255, 255, 0.4);
        background-color: transparent;
        height: 45px;
        padding-left: 13px;
        outline: none;
        
        color: ${color.white};
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        
        &::placeholder {
          color: rgba(255,255,255,0.3);
        }
        
        &.not-valid {
          border: solid 1px ${color.darkPink};
        }
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: ${color.white};
        -webkit-box-shadow: 0 0 0 1000px ${color.darkPurple} inset;
        border: 1px solid rgba(255, 255, 255, 0.4);
        transition: background-color 5000s ease-in-out 0s;
      }
      
      & > textarea {
        height: 150px;
        resize: none;
        padding-top: 10px;
      }
      
      & .error {
        & > span {
          font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 1);
        }
      }
      
      &.fluid {
        width: 100%;
        
        & > input, & > textarea {
          width: 100%;
        }
      }
      
      &.no-top-margin {
        margin-top: 0;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    & > form {
      & .field-wrap {
        &.submit-button {
          margin-top: 35px;
        }
      }
    }
  }
`;

const SubmitButton = styled.button`
  ${resetButton};
  
  width: 315px;
  height: 44px;
  border-radius: 4px;
  background-color: ${color.darkPink};
  
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${color.catskillWhite};
  
  &:hover {
    background-color: rgba(251,2,80,0.8);
  }
  
  &:active {
    background-color: rgba(251,2,80,0.9);
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  
    &:hover, &:active {
      background-color: ${color.darkPink};
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const MsgWrap = styled.div`
  ${flex};
  justify-content: flex-start;
  display: none;
  user-select: none;
  
  &.popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${color.darkPurple};
    z-index: 3;
    flex-direction: column;
    justify-content: center;
    
    & > p {
      margin-bottom: 0;
    }
  }
  
  &.show {
    display: flex;
    height: 180px;
  }
  
  & > h2 {
    margin: 0 0 10px 0;
  }
`;

const errors = {
  required: dictionary.formRequiredField,
  minValues: dictionary.formMin100Chars,
};

const showErrorMsg = (msg) => {
  const message = document.getElementById('error-message');
  if (msg) message.innerText = msg;
  message.classList.add('show');
};

const showSuccessMsg = (msg) => {
  const message = document.getElementById('success-message');
  const messageError = document.getElementById('error-message');
  const form = document.getElementById('withdraw-form');
  if (form) form.style.height = '150px';
  if (msg) message.innerText = msg;
  messageError.classList.remove('show');
  message.classList.add('show');

  const timer = setTimeout(() => {
    clearTimeout(timer);
    if (form) form.style.height = '100%';
    if (message) message.classList.remove('show');
  }, 3000);
};

const WithdrawForm = () => {
  const [state] = useContext(StoreContext);
  const { user: { userName } } = state;

  const [username] = useState(userName);
  const [validating, setValidating] = useState(false);

  const initialValues = {
    username,
    comment: '',
    emojis: 'love',
    stars: 'star5',
  };

  const submit = (values, { resetForm }) => {
    const data = { ...values };
    data.rating = data.stars.replace('star', '');
    data.emoji = values.emojis;
    delete data.stars;
    delete data.emojis;
    freespinWithdrawComment(data)
      .then((json) => {
        if (json.success) {
          showSuccessMsg();
          resetForm({});
        } else {
          showErrorMsg();
        }
      });
  };
  const buttonSubmit = () => (setValidating(true));

  return (
    <Wrap>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object()
          .shape({
            username: Yup.string()
              .required(errors.required),
            comment: Yup.string()
              .min(100, errors.minValues)
              .required(errors.required),
            emojis: Yup.string()
              .required(errors.required),
            stars: Yup.string()
              .required(errors.required),
          })}
        render={({ errors, touched, isValid, values }) => (
          <Form id="withdraw-form" className="withdraw-form">

            <MsgWrap id="error-message">
              <p>การส่งแบบฟอร์มล้มเหลว กรุณาลองอีกครั้ง</p>
            </MsgWrap>

            <MsgWrap id="success-message" className="popup">
              <h2>ติดต่อ Call Center</h2>
              <p>ระบบได้รับความคิดเห็นของคุณแล้ว</p>
              <p>กรุณาติดต่อ Call Center เพื่อรับเงินได้ที่</p>
              <p>
                <Link to={process.env.LINE_LINK} target="_blank" rel="noopener noreferrer" aria-label="Call">ลิงค์</Link>
              </p>
            </MsgWrap>

            {/* Username */}
            <div className="field-wrap fluid">
              <label htmlFor="username">ยูสเซอร์เนม</label>
              <FormError
                errors={errors}
                touched={touched}
                fieldKey="username"
                validating={validating}
              />
              <Field
                type="text"
                name="username"
                className={`username ${isError(errors, touched, 'username') &&
                isValidField('username', errors, touched, validating) ? 'not-valid' : ''}`}
                placeholder="ยูสเซอร์เนม (e.g. AOOG000)"
                value={values.username || ''}
              />
            </div>

            {/* Comment */}
            <div className="field-wrap fluid">
              <label htmlFor="comment">ความคิดเห็นของคุณ</label>
              <FormError
                errors={errors}
                touched={touched}
                fieldKey="comment"
                validating={validating}
              />
              <Field
                component="textarea"
                name="comment"
                className={`comment ${isError(errors, touched, 'comment') &&
                isValidField('comment', errors, touched, validating) ? 'not-valid' : ''}`}
                placeholder="คิดเห็น"
                value={values.comment || ''}
              />
            </div>

            {/* Emojis */}
            <div className="field-wrap fluid">
              <RadioButtonGroup
                label="ความรู้สึกของคุณ"
                stars={EmojiIcons}
                background
                value={values.emojis}
                error={errors.emojis}
                touched={touched.emojis}
              >
                <Field
                  component={RadioButton}
                  name="emojis"
                  id="smiley"
                  label="1"
                />
                <Field
                  component={RadioButton}
                  name="emojis"
                  id="crazy"
                  label="2"
                />
                <Field
                  component={RadioButton}
                  name="emojis"
                  id="cowboy"
                  label="3"
                />
                <Field
                  component={RadioButton}
                  name="emojis"
                  id="devil"
                  label="4"
                />
                <Field
                  component={RadioButton}
                  name="emojis"
                  id="love"
                  label="5"
                />
              </RadioButtonGroup>
            </div>

            {/* Stars */}
            <div className="field-wrap fluid no-top-margin">
              <RadioButtonGroup
                label=""
                className="field-wrap"
                stars={StarsIcons({
                  active: values.stars,
                })}
                value={values.radioGroup}
                error={errors.radioGroup}
                touched={touched.radioGroup}
              >
                <Field
                  component={RadioButton}
                  name="stars"
                  id="star1"
                  label=""
                />
                <Field
                  component={RadioButton}
                  name="stars"
                  id="star2"
                  label=""
                />
                <Field
                  component={RadioButton}
                  name="stars"
                  id="star3"
                  label=""
                />
                <Field
                  component={RadioButton}
                  name="stars"
                  id="star4"
                  label=""
                />
                <Field
                  component={RadioButton}
                  name="stars"
                  id="star5"
                  label=""
                />
              </RadioButtonGroup>
            </div>

            {/* Submit button */}
            <div className="field-wrap fluid submit-button">
              <SubmitButton
                className="submit"
                type="submit"
                disabled={!isValid && validating}
                onClick={buttonSubmit}
              >ส่งความคิดเห็นและรับเงิน
              </SubmitButton>
            </div>
          </Form>
        )}
      />
    </Wrap>
  );
};

export default WithdrawForm;
